class Modal {
  constructor(options) {
    let dftOptions = {
      isOpen: () => { },
      isClose: () => { },
      selector: '.popup',
    };
    this.options = Object.assign(dftOptions, options);
    this.modal = document.querySelector(dftOptions.selector);
    if (!this.modal) {
      this.modal = createNode('div', {
        classList: 'popup',
      });
      document.body.appendChild(this.modal);
    }
    this.speed = 350;
    this.animation = false;
    this.isOpen = false;
    this.modalContainer = false;
    this.styleModal = createNode('style');
    this.styleModal.innerHTML = `
    .popup {
      --animation-time: var(--animation-time);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      background-color: rgba(27,29,31, .5);
      cursor: pointer;
      overflow-y: auto;
      overflow-x: hidden;
      transition: var(--animation-time);
      opacity: 0;
      visibility: hidden;
      transition: all var(--animation-time) ease-in-out;
      box-sizing: border-box;
      text-align: center
    }
    
    .popup::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%
    }
    
    .modal__container {
      position: relative;
      width: 90vw;
      margin-top: 5vh;
      margin-bottom: 5vh;
      display: none;
      vertical-align: middle;
      background-color: #fff;
      padding: 10px 15px;
      border-radius: 20px;
      cursor: default;
      box-sizing: border-box;
      transition: all var(--animation-time) ease-in-out
    }
    
    .modal__container.modal-open {
      display: inline-block
    }
    .modal__container.modal-main{
      max-width:600px;
      border-radius: 15px;
      border: 1px solid #E3E3E8;
      background: var(--white, #FFF);
      box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.15);
      padding:24px 30px 30px;
      overflow: hidden;
    }
    .modal-main__top{
     margin-bottom: 20px; 
    }
    .modal-main__close.modal-close{
      background-color: #efefef;
      background-size: 15px 15px;
      background-position: center;
      background-repeat: no-repeat;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .modal-main__title{
      font:500 20px/26px var(--font);
      color:var(--gray-800);
      margin:0;
      display: inline-flex;
    }
    .modal-content.confirm{
      display:flex;
      gap:20px;
      align-items: flex-start;
    }
    .modal-form{
      display:flex;
      flex-direction:column;
      gap:20px;
      width: 100%;
      transition:all .12s ease-in-out;
    }
    .modal-form.hide{
      position: absolute;
      transform: translate(-150%);
    }
    .modal-form__label{
      display: block; 
      width:100%;
      position:relative;
    }
    .modal-form__input{
      padding:13px 20px;
      width:100%;
      border-radius: 6px;
      border: 1px solid #DEDEE2;
      background: #ffffff;
      font:400 18px/22px var(--font);
      color:var(--gray-800);
    }
    .modal-form__input.warning{
      border-color: var(--notice);
    }
    .modal-form__input::placeholder{
      font:400 15px/19px var(--font);
      color:var(--gray-700);
    }
    .modal-form__text{
      font:400 14px/20px var(--font);
      color:var(--gray-750);
      position:absolute;
      top:-14px;
      left: 15px;
      padding: 0 5px;
      background-color: var(--white);
      font:400 14px/22px var(--font);
    }
    .modal-form__text--textarea{
      top:-10px;
    }
    .modal-form__flag{
      color:var(--notice);
    }
    .modal-form__textarea{
      resize:none;
      height:200px;
      padding:13px 20px;
      width:100%;
      border-radius: 6px;
      border: 1px solid #DEDEE2;
      background: #ffffff;
      margin: 0;
      font:400 18px/22px var(--font);
      color: var(--gray-800);
    }
    .modal-form__textarea::placeholder{
      font:400 14px/22px var(--font);
      color:var(--gray-700);
    }
    .modal-form__textarea,.modal-form__input{
      outline:none;
    }
    .modal-form__textarea:focus,.modal-form__input:focus{
      border-color:var(--navy-blue);
    }
    .modal-form__required-value{
      font: 400 14px/17px var(--font);
      color:var(--gray-800);
      text-align: left;
      margin-top: -10px;
    }
    .modal-form__required-value .modal-form__flag{
      font-size:14px;
      line-height:18px;
    }
    .modal-form__sogl{
      display: flex;
      text-align: left;
      gap: 8px;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .modal-form__btn{
      padding: 14px;
      font: 400 18px/20px var(--font);
      color: var(--gray-800);
      background-color: var(--main-bg-btn);
      text-align: center;
      border-radius: 6px;
      box-shadow: var(--df-shadow-reset);
      transition: var(--df-anim);
      transform: scale(1);
    }
    .modal-form__btn::first-letter {
      text-transform:uppercase;
    }
    
    .modal-form__btn:hover{
      box-shadow: var(--df-shadow);
    }
    .modal-form__btn:active{
      transform:scale(0.95);
    }
    .modal-form__sogl-text{
      font:400 14px/17px var(--font);
      color:var(--gray-750);
    }
    .modal-form__confirm{
      display:flex;
      flex-direction:column;
      gap: 20px;
      transition:all .12s ease-in-out;
    }
    .modal-form__confirm.hide{
      position: absolute;
      transform: translate(150%);
    }
    .modal-form__confirm-text{
      font: 400 16px/18px var(--font);
      color: var(--gray-800);
      text-align: left;
    }
    .popup.is-open {
      opacity: 1;
      visibility: visible;
      transition: all var(--animation-time) ease-in-out
    }
    
    .fade {
      opacity: 0;
      transition: all var(--animation-time) ease-in-out
    }
    
    .fade.anim-run {
      opacity: 1;
      transition: all var(--animation-time) ease-in-out
    }
    
    .fadeInUp {
      opacity: 0;
      transform: perspective(600px) translate(0px, -100%) rotateX(45deg);
      transition: all var(--animation-time) ease-in-out
    }
    
    .fadeInUp.anim-run {
      opacity: 1;
      transform: perspective(600px) translate(0px, 0%) rotateX(0deg);
      transition: all var(--animation-time) ease-in-out
    }
    .modal-close {
      margin-left: auto;
      width: 20px;
      height: 20px;
      background-color: rgba(0, 0, 0, 0);
      background-image: url("data:image/svg+xml,%3Csvg version=%271.1%27 id=%27Capa_1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 x=%270px%27 y=%270px%27 viewBox=%270 0 512.001 512.001%27 style=%27enable-background:new 0 0 512.001 512.001;%27 xml:space=%27preserve%27%3E%3Cg%3E%3Cg%3E%3Cpath d=%27M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z%27/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      cursor: pointer
    }
    .order-comfirm,.order-error{
      font: 400 18px/20px var(--font);
      color: var(--gray-800);
    }
    `;
    document.head.appendChild(this.styleModal);
    this.events();
    this.haveChildInside();
  }
  events() {
    if (this.modal) {
      document.addEventListener('click', (event) => {
        const clickedElement = event.target.closest('[data-modal-btn]');
        if (clickedElement) {
          let target = clickedElement.dataset.modalBtn;
          console.log(target);
          let animation = clickedElement.dataset.animation;
          let speed = clickedElement.dataset.speed;

          this.animation = animation ? animation : 'fade';
          this.speed = speed ? parseInt(speed) : 300;
          this.modalContainer = document.querySelector(`[data-modal=${target}]`);
          this.open();
          return;
        };

        if (event.target.closest('.modal-close')) {
          this.close();
          return;
        }
      });
      window.addEventListener('keyup', (event) => {
        if (event.which == 27) {
          if (this.isOpen) {
            this.close();
          }
        }
      });
      this.modal.addEventListener('click', (event) => {
        if (!event.target.classList.contains('modal__container') && !event.target.closest('.modal__container') && this.isOpen) {
          this.close();
        }
      });
    }
  }
  open(nameModal) {
    let flag = nameModal ? true : false;
    if (flag) {
      this.modalContainer = document.querySelector(`[data-modal=${nameModal}]`);
    }
    this.modal.style.setProperty('--animation-time', `${this.speed / 1000}s`);
    this.modal.classList.add('is-open');
    this.disableScroll();

    this.modalContainer.classList.add('modal-open');
    this.modalContainer.classList.add(this.animation);

    setTimeout(() => {
      this.modalContainer.classList.add('anim-run');
      this.options.isOpen(this);
      this.isOpen = true;
    }, this.speed);
  }
  close() {
    this.modal.className = "popup";
    if (this.modalContainer) {
      this.modalContainer.classList.remove('anim-run');
      setTimeout(() => {
        this.modalContainer.classList.remove(this.animation);
        this.modal.classList.remove('is-open');
        this.modalContainer.classList.remove('modal-open');
        this.enableScroll();
        this.options.isClose(this);
        this.isOpen = false;
      }, this.speed);
      if (this.modalContainer.dataset.modalRemove === 'true') {
        setTimeout(() => {
          this.modal.removeChild(this.modalContainer);
        }, this.speed + 100);
      }
    }
  }
  showModal(nameModal) {
    this.open(nameModal);
  }
  disableScroll() {
    this.lockPadding();
    document.body.classList.add('disable-scroll');
  }
  enableScroll() {
    this.unlockPadding();
    document.body.classList.remove('disable-scroll');
  }
  lockPadding() {
    let paddingOffset = window.innerWidth - document.documentElement.clientWidth + 'px';
    document.body.style.paddingRight = paddingOffset;
  }
  unlockPadding() {
    document.body.style.paddingRight = 0;
  }
  haveChildInside() {
    let childs = document.querySelectorAll(".modal__container");
    if (!(childs.length > 0)) return false;
    childs.forEach(child => {
      if (!child.parentNode.classList.contains("popup")) {
        this.modal.appendChild(child);
      }
    });
    return true;
  }
}
class SnapSlider {
  constructor(idNode, options) {
    const defaultOptions = {
      speed: 350,
      itemsToScroll: 6,
      draggable: false
    };
    this.options = Object.assign({}, defaultOptions, options);
    this.slider = document.getElementById(idNode);
    if (!this.slider) {
      return;
    }
    this.slider.wrapper = this.slider.querySelector('.categories');
    //this.navigationBlock = document.querySelector(`[data-controls-slider="${idNode}"]`);
    this.duration = this.options.speed;
    this.itemsToScroll = this.options.itemsToScroll;
    this.scrollAmount = 0;

    this.events();
  }

  events() {
    if (!this.slider) return;

    this.prevBtn = document.querySelector('[data-slider-btn="prev"]');
    this.nextBtn = document.querySelector('[data-slider-btn="next"]');

    if (this.prevBtn) {
      this.prevBtn.addEventListener('click', () => this.actionPrev());
    }

    if (this.nextBtn) {
      this.nextBtn.addEventListener('click', () => this.actionNext());
    }
    if(this.options.draggable) {
      this.slider.addEventListener('mousedown', this.onDragStart.bind(this));
      this.slider.addEventListener('touchstart', this.onDragStart.bind(this));
      this.slider.addEventListener('mousemove', this.onDragMove.bind(this));
      this.slider.addEventListener('touchmove', this.onDragMove.bind(this));
      this.slider.addEventListener('mouseup', this.onDragEnd.bind(this));
      this.slider.addEventListener('mouseleave', this.onDragEnd.bind(this));
      this.slider.addEventListener('touchend', this.onDragEnd.bind(this));
      this.slider.addEventListener('touchcancel', this.onDragEnd.bind(this));
    }
    this.updateButtonsState();
  }
  onDragStart(event) {
    this.isDragging = true;
    this.startX = event.type.includes('mouse') ? event.pageX : event.touches[0].pageX;
    this.scrollStart = this.slider.scrollLeft;
  }
  onDragMove(event) {
    if (!this.isDragging) return;
    const x = event.type.includes('mouse') ? event.pageX : event.touches[0].pageX;
    const walk = (x - this.startX) * 1.5; //scroll-fast
    this.slider.scrollLeft = this.scrollStart - walk;
  }

  onDragEnd() {
    this.isDragging = false;
    this.scrollAmount = this.slider.scrollLeft;
    this.updateButtonsState();
  }
  actionPrev() {
    const slideWidth = this.slider.offsetWidth;
    const scrollBy = this.itemsToScroll * slideWidth / this.slider.wrapper.children.length;
    this.scrollAmount = Math.max(this.scrollAmount - scrollBy, 0);
    this.scrollTo(this.scrollAmount);
  }

  actionNext() {
    const slideWidth = this.slider.offsetWidth;
    const scrollBy = this.itemsToScroll * slideWidth / this.slider.wrapper.children.length;
    const maxScroll = this.slider.scrollWidth - this.slider.clientWidth;
    this.scrollAmount = Math.min(this.scrollAmount + scrollBy, maxScroll);
    this.scrollTo(this.scrollAmount);
  }

  scrollTo(position) {
    const start = this.slider.scrollLeft;
    const change = position - start;
    const increment = 20;
    let currentTime = 0;
    const duration = this.duration;

    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      this.slider.scrollLeft = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        this.updateButtonsState();
      }
    };
    animateScroll();
  }

  easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  updateButtonsState() {
    const maxScroll = this.slider.scrollWidth - this.slider.clientWidth;
    if (this.prevBtn) {
      this.prevBtn.disabled = this.scrollAmount <= 0;
      this.scrollAmount <= 0 ? this.prevBtn.classList.add("visually-hidden") : this.prevBtn.classList.remove("visually-hidden");
    }
    if (this.nextBtn) {
      this.nextBtn.disabled = this.scrollAmount >= maxScroll;
      this.scrollAmount >= maxScroll ? this.nextBtn.classList.add("visually-hidden") : this.nextBtn.classList.remove("visually-hidden");
    }
  }
}
class Preloader {
  constructor(options) {
    let dftOptions = {
      parrentNodeSelector: 'body',
      clobalLader: false,
    };
    this.options = Object.assign(dftOptions, options);
    this.parrentNodeSelector = document.querySelector(this.options.parrentNodeSelector);
    this.node = this.canvasPreloader();
  }
  canvasPreloader() {
    const preloaderWrapper = createNode('DIV', {
      className: 'loading',
      id: 'preloader',
      style: `opacity: .9;${this.options.clobalLader ? 'position:fixed' : ''}`,
    });
    const preloaderElement = createNode('DIV', {
      id: 'loader',
    });
    preloaderWrapper.appendChild(preloaderElement);
    return preloaderWrapper;
  }
  showPreloader() {
    this.parrentNodeSelector.appendChild(this.node);
    this.options.clobalLader ? this.disableScroll() : false;
  }
  closePreloader() {
    this.parrentNodeSelector.removeChild(this.node);
    this.options.clobalLader ? this.enableScroll() : false;
  }
  closeStartPreloader() {
    if (document.readyState == 'complete') {
      let startPreloader = document.querySelector('#preloader');
      this.parrentNodeSelector.removeChild(startPreloader);
    }
  }
  disableScroll() {
    document.body.classList.add('disable-scroll');
  }
  enableScroll() {
    document.body.classList.remove('disable-scroll');
  }
}
class Tooltip {
  constructor(classTooltip, options) {
    let dftOption = {
      selector: classTooltip ? classTooltip : '.js-tooltip',
      isOpen: () => { },
      isClose: () => { },
    };

    this.options = Object.assign(dftOption, options);
    this.tooltipBlock = document.querySelector(dftOption.selector);
    this.speed = 350;
    this.animation = false;
    this.isOpen = false;
    this.shadow = false;
    this.round = false;
    this.notice = false;
    this.init();
  }
  init() {
    if (!this.tooltipBlock) return;
    this.position = this.getPosition();
    this.tooltipBtn = this.tooltipBlock.querySelector('[data-tooltip-btn="js-tooltip-btn"]');
    this.createTooltip();
    this.styleTooltip = createNode('style');
    this.styleTooltip.innerHTML = `
    /*tooltipBlock*/
      .js-tooltip__btn{
        font:400 16px/16px var(--font);
        color:var(--gray-800);
        border-radius:5px;
        width:25px;
        height:25px;
        display:flex;
        align-items:center;
        justify-content:center;
      background: linear-gradient(174deg, rgba(255,255,255,0.8939776594231442) 4%, rgba(189,189,189,0.8491597322522759) 100%);
      transition: background .22s ease-in-out;
      }
      .js-tooltip__btn:hover{
      background: linear-gradient(174deg, rgba(255,255,255,0.8939776594231442) 4%, rgba(204,204,204,0.8491597322522759) 100%);}
      .js-tooltip__wrap{
        position:absolute;
        max-width:300px;
        padding:7px;
        background-color:#ffffff;
        border-radius:5px;
        text-align:center;
        opacity:0;
        visibility:hidden;
        width:300px;
        transition:all .22s ease-in-out;
        box-shadow:0 0 10px rgba(0,0,0, .2);
      }
      .js-tooltip.open .js-tooltip__wrap{
        opacity:1;
        visibility:visible
      }
      .js-tooltip__wrap.left-side.top{
        right:calc(100% + 5px);
        top:-5px;
      }
      .js-tooltip__wrap.left-side.center{
        right:calc(100% + 5px);
        top:50%;
        transform:translateY(-50%);
      }
      .js-tooltip__wrap.left-side.bottom{
        right:calc(100% + 5px);
        bottom:-5px;
      }
      .js-tooltip__wrap.bottom-side.left{
        right:auto;
        left:0;
        top:calc(100% + 5px);
      }
      @media(max-width:700px){
        .js-tooltip__wrap{
          width:220px;
        }
      }
      /*tooltipBlock*/
    `;
    document.head.appendChild(this.styleTooltip);
    this.events();
  }
  getPosition() {
    let optionPosition = this.tooltipBlock.dataset.tooltipPosition;
    let classPosition = 'left-side center';
    if (optionPosition) {
      classPosition = optionPosition.replace(',', ' ');
    }
    if (document.body.clientWidth <= 700) {
      classPosition = 'bottom-side left';
    }
    return classPosition;
  }
  createTooltip() {
    if (!this.tooltipBlock) return;
    this.tooltipBlock.style.position = 'relative';
    this.notice = this.tooltipBlock.dataset.tooltipText ? this.tooltipBlock.dataset.tooltipText : false;

    this.notice = this.notice ? this.notice : 'Текст для тултип не задан в атрибуте data-tooltip-text';
    let wrapNode = this.createNode('DIV', {
      className: `js-tooltip__wrap ${this.position}`,
    });
    let textNode = this.createNode('P', {
      className: 'js-tooltip__text mr-reset',
      style: 'font:400 12px/20px var(--font); color:var(--gray-700)',
      textContent: this.notice,
    });
    wrapNode.appendChild(textNode);
    this.tooltipBlock.appendChild(wrapNode);

    return this.tooltipBlock;
  }
  createNode(tagNode, attributes) {
    const node = document.createElement(tagNode);
    Object.assign(node, attributes);
    return node;
  };
  events() {
    if (!this.tooltipBlock) return;
    document.addEventListener('click', (event) => {
      const tooltipBtn = event.target.closest('[data-tooltip-btn]');
      tooltipBtn
        ? this.open(tooltipBtn)
        : this.close();
    });
  }
  open(target) {
    target.classList.add('open');
    this.tooltipBlock.classList.toggle('open');
  }
  close() {
    if (!this.tooltipBtn.classList.contains('open')) return;
    this.tooltipBlock.classList.remove('open');
    this.tooltipBtn.classList.remove('open');
  }
  detectMobile() {
    let navigRegex = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
    let isMobile = navigRegex.test(window.navigation.userAgent) || document.documentElement.classList.contains('bx-touch');
    return isMobile;
  }
}



class Accordion {
  constructor(selector, option) {
    let dftOption = {
      visibileOneContent: false,
      visibileItem: null,
    };
    this.option = Object.assign(dftOption, option);
    this.selector = selector;
    this.accordion = document.querySelector(`[data-accordion=${selector}]`);
    if (this.accordion) {
      this.speed = this.accordion.dataset.animationSpeed ?? 700;
      this.accordionItems = this.accordion.querySelectorAll('[data-accordion-item=accordion-item]');
      this.accordionBtns = this.accordion.querySelectorAll('[data-accordion-btn]');
      this.accordionContent = this.accordion.querySelectorAll('[data-accordion-content]');
    } else {
      console.error('Селектора data-accordion не существует');
    }

    this.check();
    this.init(this.option.visibileItem);
  }
  check() {
    if (this.accordionBtns.length !== this.accordionContent.length) {
      console.error('Колличество кнопок не совподает с колличеством контента');
    }
    if (!this.accordionBtns) {
      console.error('Требуется у кнопок прописать data-accordion-btn');
    }
    if (!this.accordionContent) {
      console.error('Требуется у блока с контентом прописать data-accordion-content');
    }
    if (!this.accordionItems) {
      console.error('Требуется у елемента одного аккордиона прописать data-accordion-item="accordion-item"');
    }
  }
  event() {
    this.accordionBtns.forEach((el) => {
      el.addEventListener('click', () => {
        !this.option.visibileOneContent ? this.showContentOne(el) : this.showContentStagger(el);
      });
    });
  }


  showContent(parent, content) {

    parent.classList.add('open');
    content.style.maxHeight = `${content.scrollHeight}px`;
  }

  showContentOne(elemBtn) {

    const content = document.querySelector(`[data-accordion-content=${elemBtn.dataset.accordionBtn}]`);
    elemBtn.parentNode.classList.toggle('open');
    if (elemBtn.parentNode.classList.contains('open')) {
      content.style.maxHeight = `${content.scrollHeight}px`;
      return;
    }
    content.style.maxHeight = null;

  }

  showContentStagger(elemBtn) {
    this.accordionItems.forEach(el => {
      el.classList.remove('open');
    });
    this.accordionContent.forEach(el => {
      el.style.maxHeight = null;
    });
    this.showContentOne(elemBtn);
  }
  init(visibileItem = null) {
    this.accordion.style.setProperty('--transition-anim', `${this.speed / 1000}s`);
    if (visibileItem === 'all') {
      this.accordionItems.forEach((el, i) => {
        const content = el.querySelector('[data-accordion-content]');
        this.showContent(el, content);
      });
    } else {
      this.accordionItems.forEach((el, i) => {
        const content = el.querySelector('[data-accordion-content]');
        parseInt(visibileItem) === i + 1 ? this.showContent(el, content) : el.classList.remove('open');
      });
    }


    for (let i = 0; i < this.accordionBtns.length; i++) {
      this.accordionBtns[i].setAttribute('data-accordion-btn', `${this.selector}${i + 1}`);
      this.accordionContent[i].setAttribute('data-accordion-content', `${this.selector}${i + 1}`);
    }
    this.event();
  }
}
